<template>
    <div class="dashboard">
        <div class="grid">
            <!-- <div class="col-12 sm:col-12 md:col-6 lg:col-12 xl:col-3 p-2"  v-if="examAdmForms.length === 0">
                <span  @click="addRecord()">
                    <div class="card p-4 border-1 surface-border surface-card border-round dashboard-card dash-add-card">
                        <div class="add-icon-container">
                            <div class="text-center mb-3">
                                <i class="pi pi-plus"></i>
                            </div>
                            <div class="text-center">
                                <h5>Open New EAF</h5>
                            </div>
                        </div>
                    </div>
                </span>
            </div> -->
            <div class="col-12 sm:col-12 md:col-6 lg:col-12 xl:col-3 p-2" v-for="(item, i) of examAdmForms" :key="i">
                <span v-if="item.name == 'For Add Button'" @click="addRecord(item)">
                    <div class="card p-4 border-1 surface-border surface-card border-round dashboard-card dash-add-card">
                        <div class="add-icon-container">
                            <div class="text-center mb-3">
                                <i class="pi pi-plus"></i>
                            </div>
                            <div class="text-center">
                                <h5>Open New EAF</h5>
                            </div>
                        </div>
                    </div>
                </span>
                <div v-else>
                    <div class="card p-2 border-1 surface-border surface-card border-round dashboard-card" @click="viewRecord(item)">
                        <div class="flex align-items-center justify-content-between gap-2">
                            <div class="info flex flex-column">
                                <div class="text-1xl font-bold p-1">{{ item.class_name }} - {{ item.exam_name }}</div>
                            </div>
                            <div>
                                <Tag :value="getSeverityText(item)" :severity="getSeverity(item)"></Tag>
                            </div>
                        </div>
                        <div class="flex flex-column align-items-center gap-3 mt-3">
                            <!-- //card -->
                            <div class="" style="width: 100%;">
                            
                                <div class="stats justify-content-between">
                                    <div class="right flex justify-content-between flex-row">
                                        <span class="title mr-3"> Assigned </span>
                                        <span class="value fs-large">{{ item.total_eafs }}</span>
                                    </div>
                                    <div class="left flex justify-content-between flex-row">
                                        <span class="title mr-3"> Submitted </span>
                                        <span class="value fs-large">{{ item.submitted_eafs }}</span>
                                    </div>
                                    <div class="left flex justify-content-between flex-row">
                                        <span class="title  mr-3"> Pending </span>
                                        <span class="value fs-large">{{ item.pending_eafs }}</span>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>

        <Toast />

        <ViewInitializationFrom v-on:formClose="closeView" v-if="showView" :selectedData="selectedData" :recordId="selectedRecordId" :showView="true" />
        <InitializationForm v-on:formClose="closeForm" v-if="showForm" :selectedData="selectedData" :recordId="selectedRecordId" :showForm="true" />
    </div>
</template>
<style>
.add-icon-container .pi-plus {
    font-size: 30px;
    color: #ef6c00;
    background: #e3d5c4;
    padding: 20px;
}
.add-icon-container {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.dash-add-card {
    min-height: 165px;
    position: relative;
}
.dashboard-card {
    /* height: 320px; */
    min-height: 165px;
    cursor: pointer;
    width: 100%;
}
.dashboard-card:hover {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}
</style>
<!-- <style scoped>
td,
th,
tr,
table {
    border-top: 1px solid black;
    border-collapse: collapse;
}

td.description,
th.description {
    width: 75px;
    max-width: 75px;
}

td.quantity,
th.quantity {
    width: 40px;
    max-width: 40px;
    word-break: break-all;
}

td.price,
th.price {
    width: 40px;
    max-width: 40px;
    word-break: break-all;
}

.centered {
    text-align: center;
    align-content: center;
}

.ticket {
    width: 155px;
    max-width: 155px;
}

img {
    max-width: inherit;
    width: inherit;
}

@media print {
    .hidden-print,
    .hidden-print * {
        display: none !important;
    }
}
</style> -->
<script>
//import DigitalClock  from "../../components/DigitalClock/DigitalClock.vue";
import ViewInitializationFrom from './Modals/ViewInitializationFrom.vue';
import InitializationForm from './Modals/InitializationForm.vue';
export default {
    data() {
        return {
            module_name: 'Dashboard',
            showForm: false,
            showView: false,
            examAdmForms: [],
            loading: true,
            selectedData: [],
            selectedRecordId: 0,
            statusOptions: [
                { label: 'ANNUAL', value: 'ANNUAL' },
                { label: 'SUPPLEMENTARY', value: 'SUPPLEMENTARY' },
            ],
        };
    },
    components: {
        //  DigitalClock,

        ViewInitializationFrom,
        InitializationForm,
    },
    created() {},

    computed: {},
    mounted() {
        this.dashboardData();
    },
    methods: {
        dashboardData() {
            this.axios
                .post('api/dashboard-data')
                .then((response) => {
                    //  console.log(response.data);
                    this.examAdmForms = response.data.admissionApplicationNums;
                    // this.leaveStatistics =  response.data.leaveStatistics;
                    this.loading = false;
                })
                .catch((error) => {
                    let message = this.customError(error.response);
                    this.showMessage(message, 'error');
                })
                .finally(() => {});
        },
        isRTL() {
            return this.$appState.RTL;
        },
        getSeverity(data) {
            switch (data.is_active) {
                case 1:
                    return 'success';
                case 0:
                    return 'danger';
                default:
                    return null;
            }
        },
        getSeverityText(data) {
            switch (data.is_active) {
                case 1:
                    return 'OPEN';
                case 0:
                    return 'CLOSED';
                default:
                    return null;
            }
        },
        onStautsChange(event) {
            // const value = event.value.value;
            // const sortValue = event.value;
            // if (value.indexOf('!') === 0) {
            //     this.sortOrder = -1;
            //     this.sortField = value.substring(1, value.length);
            //     this.sortKey = sortValue;
            // } else {
            //     this.sortOrder = 1;
            //     this.sortField = value;
            //     this.sortKey = sortValue;
            // }
        },
        viewRecord(data) {
            this.selectedRecordId = data.id;
            this.selectedData = JSON.stringify(data);
            this.showView = true;
        },
        addRecord(data) {
            this.selectedRecordId = data.id;
            this.selectedData = JSON.stringify(data);
            this.showForm = true;
        },

        closeForm() {
            // if(!this.selectedRecordId){
            this.dashboardData();
            // }
            this.showForm = false;
        },
        closeView() {
            // if(!this.selectedRecordId){
            this.dashboardData();
            this.showView = false;
        },
    },
    watch: {},
};
</script>
