<template>  
    <BlockUI :blocked="blockComponent">
        <Toast />
        <ConfirmPopup></ConfirmPopup>
        <Sidebar v-model:visible="formVisible" :baseZIndex="1000" :autoZIndex="true"  :dismissable="false"  @hide="closeChangeSubjectForm" position="right" :class="'col-12 lg:col-8 md:col-10 sm:col-12 xs:col-12 mt-0'"> 
            <form  @keydown="form.onKeydown($event)" enctype="multipart/form-data"> 
                <div class="mt-0 mb-0">
                    <div class="col-12 lg:col-12 mb-0">
                        <h3 class="mb-0"><span v-if="recordId && recordId > 0">Update</span> Papers</h3> 
                    </div>
                    <Divider class="mt-0" />
                    <div class="grid modal-body">  
                        <div class="col-12 lg:col-12 sm:col-12 md:col-12">
                            <div>
                                <div class="grid">
                                    <div class="col-12 lg:col-12 sm:col-12 mb-2 text-center"> 
                                        <h2>{{form.name}} {{form.father_name}} ({{form.roll_no}})</h2>
                                    </div>
                                    <div class="col-12 lg:col-12 sm:col-12 mb-2">
                                        <DataTable :resizableColumns="true" columnResizeMode="fit" ref="dt" :value="subjects" sortMode="multiple" class="p-datatable-gridlines p-datatable-sm" dataKey="paper_code" :rowHover="true" 
                                         :loading="loading" responsiveLayout="scroll"
                                        v-model:selection="selectedSubjects" 
                                        :scrollable="true" scrollHeight="400px">
                                    
                                        
                                        <template #empty> 
                                            <div class="table-message"> 
                                                <img class="table-message-img" src="layout/images/no-record-found.png" alt="No record found" >
                                            </div>
                                        </template>
                                        <template #loading>
                                            Loading data. Please wait.
                                        </template> 
                                        
                                        <Column selectionMode="multiple" style="max-width:3rem;flex-grow:1; flex-basis:160px"></Column>
                                        <Column v-for="(col, index) of selectedColumns" :field="col.field" :header="col.header" 
                                            :exportable="col.field == 'action' ? false:true" :sortable="col.field == 'action' ? false:true" 
                                            :key="col.field + '_' + index" :class="col.field == 'action' ? 'btnAction':''" 
                                            :style="col.field !== 'action' ? 'min-width:12rem;flex-grow:1; flex-basis:160px;':'max-width:5rem;flex-grow:1; flex-basis:160px;'"> 
                                        
                                        
                                            <template  #body="{data}">
                                            
                                               <span v-if="col.field == 'subject_title'">{{ data.subject_title }}</span>
                                                <span v-if="col.field == 'fee'">{{ data.fee }}</span>
                                                <span v-if="col.field == 'late_fee'">{{ data.late_fee }}</span>
                                               
                                            
                                                
                                            </template>
                                        
                                
                                        </Column>   
                                        </DataTable>
                                    </div>
                                    <div class="col-12 lg:col-12 sm:col-12 mt-2"> 
                                        <h4>History of Papers Change</h4>
                                    </div>
                                    <div class="col-12 lg:col-12 sm:col-12 mb-2">
                                        <DataTable ref="dt" :value="subjects_history" columnResizeMode="fit" class="p-datatable-sm" dataKey="id" :rowHover="true" 
                                        :loading="loading" responsiveLayout="scroll" :scrollable="true" scrollHeight="400px">
                                    
                                        
                                        <template #empty> 
                                            No history of papers change
                                        </template>
                                        <template #loading>
                                            Loading data. Please wait.
                                        </template> 
                                        
                                        <Column v-for="(col, index) of selectedColumnsHistory" :field="col.field" :header="col.header" 
                                            :exportable="col.field == 'action' ? false:true" 
                                            :sortable="col.field == 'date_added' ? false : (col.field == 'subjects' ? false : true)" 
                                            
                                            :key="col.field + '_' + index" :class="col.field == 'action' ? 'btnAction':''"
                                            :style="col.field !== 'action' ? (col.field == 'date_added' ? 'width:max-content; flex-grow:0; white-space: nowrap; padding: 10px 10px;' : 'width:auto; flex-grow:1; padding: 10px 10px;') : 'min-width:5rem; flex-grow:1; flex-basis:10%; padding: 10px 10px;'">
                                            <template  #body="{data}">
                                                
                                                <span v-if="col.field == 'date_added'">{{ formatDate(data.date_added)  }}</span>
                                                <span v-if="col.field == 'subjects'"> {{ data.subjects == '()' ? 'No Subjects was selected' : data.subjects }}</span>
                                                
                                                
                                            </template>
                                        
                                
                                        </Column>   
                                        </DataTable>
                                    </div>
                                </div>
                                
                                
                            </div>  
                            

                        </div> 
                        
                    </div>
                    <Divider />
                    <div class="col-12 lg:col-12">
                        <Toolbar class="pl-0 pr-0">
                            <template v-slot:start>
                                <Button type="button" label="Save and Close" :loading="isSaving" icon="pi pi-check" @click="saveAndClose=true, save_selected_subjects()" class="p-button-raised p-button-lg p-button-info mr-2"/>
                                <!-- <Button type="button" label="Reset" icon="pi pi-refresh" class="p-right p-button-raised p-button-lg p-button-warning mr-2" @click="resetForm()"/> -->
                            </template>
                            <template v-slot:end> 
                                <Button type="button" label="Cancel" icon="pi pi-times" class="p-right p-button-raised p-button-lg p-button-danger mr-2" @click="closeChangeSubjectForm"/>
                            </template> 
                        </Toolbar>   
                    </div>
                </div> 
            </form>
        </Sidebar> 
    </BlockUI>
    <ConfirmDialog group="dialog" />
</template>
<style scoped>
    .active-room{
        background-color: aquamarine !important;
    }
</style>
<script> 
import Dropdown from 'primevue/dropdown';
export default {
    data() {
			return {  
                form: new this.Form({
                    id:'',
                    student_id:'',
                    name: '', 
                    email:'',
                    phone: '', 
                    father_name: '', 
                    roll_no:'',
                    nums_reg_no: '', 
                    cnic:'',
                    mailing_address: '', 
                }),   
              
                module_name:'Application Form',  
                isSaving:false,  
                saveAndClose:false, 
                recId:0,
                loadingComponent:true,
                formVisible:false, 
                selectedClass: [],
                subjects: null,
                subjects_history: null,
                selectedSubjects: null,
              

			}
		},   
        props:{
            showForm : {
                type : Boolean,
                required : true,
            },
            recordId :{
                type : Number,
            },
            selectedData:{
                type : String,
            }
        },
		created() {  
            this.columns = [
                    {field: 'subject_title', header: 'Paper Name'}, 
                    {field: 'fee', header: 'Fees'}, 
                    {field: 'late_fee', header: 'Late Fees'}, 
                   
                ];
            this.selectedColumns = this.columns;
            this.columns_history = [
                    {field: 'date_added', header: 'Date Changed'}, 
                    {field: 'subjects', header: 'Paper Names'}, 
                ];
            this.selectedColumnsHistory = this.columns_history;
		},
        computed: { 
        },
		mounted() {  
            this.resetForm();
            this.getAllRequestData();  
            this.formVisible = this.showForm; 
            this.recId = this.recordId;
            
            this.selectedData ? this.form.fill(JSON.parse(this.selectedData)) : null;
            this.getAllClasses();
		},
		methods: { 
            getAllClasses() {
            var Vthis = this;
            // this.loading = true;
            var add_app_id =  this.form.id;
            var student_id = this.form.student_id;
            this.axios
                .post('api/get-student-subjects',{ student_id: student_id,add_app_id: add_app_id})
                .then((response) => {
                    this.subjects = response.data.all_papers;
                    this.selectedSubjects = response.data.current_papers;
                    this.subjects_history = response.data.subject_history;
                    
                })
                .catch((error) => {
                    let message = Vthis.customError(error.response);
                    Vthis.showMessage(message, 'error');
                })
                .finally(() => {});
            },  
            getAllRequestData(){   
                this.$axios.all([])   
                .then(() => {  
                    // this.viewRecord();   
                }) 
                .then(() => { 
                    this.blockComponent = false;     
                }).catch(() => {    
                //    this.getAllRequestData();
                })
                .finally(() => {  
                }); 
            },
           
            resetForm(){
                this.form.reset();
            },
            save_selected_subjects() {
           
            var Vthis = this;
            var add_app_id =  this.form.id;
            const selectedIds = this.selectedSubjects.map(item => item.paper_code);

            // this.loading = true;
            this.axios
                .post('api/store-student-subjects', { selected_paper_codes: selectedIds,add_app_id: add_app_id})
                .then((response) => {
                    // this.loading = false;
                    
                    if(this.saveAndClose){   
                        this.saveAndClose = false;
                        this.closeChangeSubjectForm();
                    }    
                    Vthis.showMessage('Saved successfully','success');
                })
                .catch((error) => {
                    let message = Vthis.customError(Vthis.form.errors.has('error') ? Vthis.form.errors.get('error') : error  ); 
                    Vthis.showMessage(message,'error'); 
                })
                .finally(() => {});
            },
          
            loadForm(){
                this.resetForm(); 
            },
            closeChangeSubjectForm(){
                this.$emit('formClose',true); 
            },
            
           
            
		},
        watch : {
             
        }
}
</script>
 
