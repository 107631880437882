<template>  
    <BlockUI :blocked="blockComponent">
        <Toast />
        <ConfirmPopup></ConfirmPopup>
        <Sidebar v-model:visible="formVisible" :baseZIndex="1000" :autoZIndex="true" :showCloseIcon="false" :dismissable="false" @hide="closeForm" position="full" :class="'col-12 lg:col-8 md:col-10 sm:col-12 xs:col-12 mt-0'"> 
            <form  @keydown="form.onKeydown($event)" enctype="multipart/form-data"> 
                <div class="mt-0 mb-0">
                    <div class="col-12 lg:col-12 mb-0">
                        <h4 class="mb-0" v-if="recordId && recordId > 0"><span>{{ form.class_name }} - {{ form.exam_name }} </span> <Tag :value="getSeverityText(form.is_active)" :severity="getSeverity(form.is_active)"></Tag>
                        </h4>
                     
                        <span  v-if="recordId && recordId > 0"><strong>Opened On: </strong> {{ formatDate(form.from_date) }} - <strong>Closed On: </strong> {{ formatDate(form.to_date) }} </span>
                        <h4 class="mb-0"><span v-if="!(recordId && recordId > 0)"> Open Exam Admission Form for a Class</span> 
                        </h4> 
                    </div>
                    <Divider class="mt-0" />
                    <div class="grid modal-body">  
                        <div class="col-12 lg:col-12 sm:col-12 md:col-12">
                            <div>
                                <div class="grid">
                                   
                                    <div class="col-12 lg:col-3 sm:col-12 md:col-6 mb-2" v-if="!(recordId && recordId > 0)">
                                        <label>Class</label>
                                        <Dropdown v-model="form.class_id" optionValue="id" :options="selectedClass" optionLabel="name"  @change="getExams" placeholder="Select a Class" class="full-width" />
                                        <HasError class="p-error" :form="form" field="class_id" />
                                    </div>

                                    
                                    <div class="col-12 lg:col-3 sm:col-12 md:col-6 mb-2"  v-if="!(recordId && recordId > 0)">
                                        <label>Exam</label>
                                        <Dropdown v-model="form.exam_id" optionValue="id" :options="selectedExam" optionLabel="name"  @change="getStudents" placeholder="Select a Exam" class="full-width" />
                                        <HasError class="p-error" :form="form" field="exam_id" />
                                    </div>

                                    
                                    
                                    <div class="col-12 lg:col-3 sm:col-12 md:col-6 mb-2">
                                        <label>Opened On</label>
                                        <Calendar  v-model="form.from_date" dateFormat="M dd, yy" showIcon showButtonBar placeholder="Select from date" class="full-width"/>
                                        <HasError class="p-error" :form="form" field="from_date" />
                                    </div>
                                    <div class="col-12 lg:col-3 sm:col-12 md:col-6 mb-2">
                                        <label>Closed On</label>
                                        <Calendar  v-model="form.to_date" dateFormat="M dd, yy" showIcon showButtonBar placeholder="Select to date" class="full-width" />
                                        <HasError class="p-error" :form="form" field="to_date" />
                                    </div>
                                    <div class="col-12 lg:col-3 sm:col-12 md:col-6 mb-2 d-flex justify-end " v-if="recordId && recordId > 0">       
                                        <label>Status</label>
                                        <div class="ml-1"><InputSwitch v-model="isFormActive" /></div>
                                        <HasError class="p-error" :form="form" field="is_active" />
                                    </div>
                                </div>
                                
                            </div>  
                            <DataTable :resizableColumns="true" :filters="filters" columnResizeMode="fit" ref="dt" :value="records" :paginator="true" class="p-datatable-gridlines p-datatable-sm"  :rows="15" dataKey="student_id" :rowHover="true" 
                                :loading="loading" responsiveLayout="scroll"
                                 :rowsPerPageOptions="[15,25,50,100]" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" v-model:selection="selectedRecords" 
                                :scrollable="true" scrollHeight="400px">
                                <template #header>
                                    <div class="flex justify-content-between flex-column sm:flex-row">  
                                        <span class="p-input-icon-left mb-2">
                                            <i class="pi pi-search" />
                                            <InputText v-model="filters['global'].value" placeholder="Keyword Search" style="width: 100%"/>
                                        </span>
                                        <MultiSelect :modelValue="selectedColumns" :options="columns" optionLabel="header" @update:modelValue="onToggle"
                                        placeholder="Select Columns" style="width: 20em"/>
                                    </div>
                                </template>
                                <template #empty> 
                                    <div class="table-message" style="text-align: center;"> 
                                        <img class="" src="layout/images/no-record-found.png" alt="No record found" >
                                    </div>
                                </template>
                                <template #loading>
                                    Loading data. Please wait.
                                </template> 
                                
                                <Column selectionMode="multiple" style="max-width:3rem;flex-grow:1; flex-basis:160px"></Column>
                                <Column v-for="(col, index) of selectedColumns" :field="col.field" :header="col.header" 
                                    :exportable="col.field == 'action' ? false:true" :sortable="col.field == 'action' ? false:true" 
                                    :key="col.field + '_' + index" :class="col.field == 'action' ? 'btnAction':''"
                                    :style="getColumnStyle(col.field)"
                                    > 
                                
                                
                                    <template  #body="{data}">
                                    
                                         <!-- <span v-if="col.field == 'id'">{{ data.id }}</span> -->
                                        <!-- <img  v-if="col.field == 'name' && data.profile_pic" :alt="data.name" :src="studentFiles+'students/'+data.student_id+'/'+data.profile_pic" class="tableProfileImg mr-2" />
                                        <img  v-if="col.field == 'name' && !data.profile_pic" :alt="data.name" :src="filesUrl+'defaults/default.png'" class="tableProfileImg mr-2" /> -->
                                        <span v-if="col.field == 'name'" @click="selectedRecordId = data.id;
                                        selectedTableData = JSON.stringify(data);
                                        " >{{ data.name }}</span>
                                        <span v-if="col.field == 'father_name'">{{ data.father_name }}</span>
                                        <span v-if="col.field == 'roll_no'">{{ data.roll_no }}</span>
                                        <span v-if="col.field == 'nums_reg_no'">{{ data.nums_reg_no }}</span>
                                        <span v-if="col.field == 'cnic'">{{ data.cnic }}</span>
                                        <span v-if="col.field == 'phone'">{{ data.phone }}</span>
                                        <span v-if="col.field == 'student_papers'"> 
                                            <MultiSelect style="width:100%" v-model="selectedPapers[data.student_id]" :ref="'multiSelect-' + data.student_id" display="chip" :options="data.papers" optionValue="paper_code" optionLabel="paper_name" placeholder="Select Papers"
                                            :maxSelectedLabels="10"   @change="handlePapersChange(data.id)" /></span>
                                        <span v-if="col.field == 'student_id'">{{ zeroPad(data.student_id, 5) }}</span>
                                      
                                        <span v-if="col.field == 'action'" @click="selectedRecordId = data.id;selectedTableData = JSON.stringify(data);">
                                            <div :class="{'ml-auto': !isRTL, 'mr-auto': isRTL}">
                                                <Button icon="pi pi-ellipsis-h" class="p-button-rounded p-button-text" :selectedTableData="JSON.stringify(data)" :recordId="data.id" @click="toggleRecordMenu"></Button>
                                                <Menu ref="menu" :popup="true" :model="actions" ></Menu>
                                            </div> 
                                        </span> 
                                        
                                    </template>
                                
                                    <template #filter="{filterModel}">
                                        <span v-if="col.field == 'name'"> 
                                            <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by name"/>
                                        </span>   
                                    
                                        <span v-else-if="col.field == 'status'">
                                            <Dropdown v-model="filterModel.value" :options="statuses" placeholder="Any" class="p-column-filter" :showClear="true">
                                                <template #value="slotProps">
                                                    <span :class="'customer-badge status-' + slotProps.value" v-if="slotProps.value">{{slotProps.value}}</span>
                                                    <span v-else>{{slotProps.placeholder}}</span>
                                                </template>
                                                <template #option="slotProps">
                                                    <span :class="'customer-badge status-' + slotProps.option">{{slotProps.option}}</span>
                                                </template>
                                            </Dropdown>
                                        </span>  
                                    </template>    
                                </Column>   
                            </DataTable>

                        </div> 
                        
                    </div>
                    <Divider />
                    <div class="col-12 lg:col-12 flex justify-content-end">
                        <Toolbar class="pl-0 pr-0">
                            <template v-slot:start>
                                
                                <!-- <Button type="submit" :label="(recordId && recordId > 0) ? 'Update' : 'Initiate'" :loading="isSaving" icon="pi pi-check" @click="save()" class="p-button-raised p-button-lg p-button-success mr-2 mb-2" v-if="!(recordId && recordId > 0)"/> -->
                                <Button type="submit" :label="(recordId && recordId > 0) ? 'Update' : 'Open'"  :loading="isSaving" icon="pi pi-check" @click="saveAndClose=true, save()" class="p-button-raised p-button-lg p-button-info mr-2"/>
                                <!-- <Button type="button" label="Reset" icon="pi pi-refresh" class="p-right p-button-raised p-button-lg p-button-warning mr-2" @click="resetForm()"/> -->
                            </template>
                            <template v-slot:end> 
                                <Button type="button" label="Cancel" icon="pi pi-times" class="p-right p-button-raised p-button-lg p-button-danger mr-2" @click="closeForm"/>
                            </template> 
                        </Toolbar>   
                    </div>
                </div> 
            </form>
        </Sidebar> 
        <ConfirmDialog group="dialog" />
    </BlockUI>
    
</template>
<style scoped>
    .active-room{
        background-color: aquamarine !important;
    }
</style>
<script> 
import { FilterMatchMode, FilterOperator } from 'primevue/api'; 
export default {
    data() {
			return {  
                form: new this.Form({ 
                    name: '', 
                    class_id:null,
                    exam_id:null,
                    exam_type:'',
                    from_date:null,
                    class_name:'',
                    exam_name:'',
                    to_date:null,
                    is_active:true,
                    std_ids:[],
                    selectedPapers:{},
                }),   
                module_name:'Initialization Form',  
                isSaving:false,  
                records: null,
                loading: false,
                saveAndClose:false, 
                recId:0,
                columns: null,
                selectedRecords: null,
                selectedColumns: null,
                loadingComponent:true,
                formVisible:false, 
                selectedClass: [],
                selectedExam: [],
                selectedTableData:[],
                selectedAddAppNum: null,
                selectedPapers: {},
                filters:{},
			}
		},   
        props:{
            showForm : {
                type : Boolean,
                required : true,
            },
            recordId :{
                type : Number,
            },
            selectedData:{
                type : String,
            }
        },
		created() {  
            this.initFilters();
            this.columns = [
                    {field: 'nums_reg_no', header: 'Registration Number'},  
                    {field: 'roll_no', header: 'Roll No'},   
                    {field: 'name', header: 'Name'}, 
                    {field: 'father_name', header: 'Father Name'}, 
                    {field: 'phone', header: 'Phone No'}, 
                    {field: 'cnic', header: 'CNIC'}, 
                    {field: 'student_id', header: 'Student ID'},    
                    {field: 'student_papers', header: 'Student Papers'}, 
                    
                ];
            this.selectedColumns  = [
                    {field: 'nums_reg_no', header: 'Registration Number'},  
                    {field: 'roll_no', header: 'Roll No'},   
                    {field: 'name', header: 'Name'},    
                    {field: 'student_papers', header: 'Student Papers'}, 
                    
                ];
            
		},
        computed: { 
            isFormActive: {
                get() {
                    return this.form.is_active === 1;
                },
                set(value) {
                    this.form.is_active = value ? 1 : 0;
                },
            },
     
            
        },
		mounted() {  
            this.resetForm();
            this.getAllRequestData();  
            this.formVisible = this.showForm; 
            this.recId = this.recordId; 
            this.selectedData ? this.form.fill(JSON.parse(this.selectedData)) : null;
            
            if (this.recordId && this.recordId > 0) {
                this.form.fill(JSON.parse(this.selectedData));
                if (this.form.from_date) {
                    const date = new Date(this.form.from_date);
                    this.form.from_date = date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: '2-digit' });
                }
                if (this.form.to_date) {
                    const date = new Date(this.form.to_date);
                    this.form.to_date = date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: '2-digit' });
                }
            }
            else
            {
                
                const date = new Date();
                this.form.from_date = date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: '2-digit' });
                
                const date_to = new Date();
                date_to.setDate(date_to.getDate() + 7);
                this.form.to_date = date_to.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: '2-digit' });
            }
            
            this.getAllClasses();
            this.getStudents()
            //this.getStudents();
            
		},
		methods: { 

            async getExams() {
                const selectedOption = this.selectedClass.find(option => option.id === this.form.class_id);
                const class_id =selectedOption.id;
                this.form.exam_id = null;
                this.records = null;
                var Vthis = this;
               //  this.loading = true;
                this.axios
                    .post('api/get-class-exam',{class_id:class_id})
                    .then((response) => {
                        this.selectedExam = response.data;
                       //  this.loading = false;
                    })
                    .catch((error) => {
                        let message = Vthis.customError(error.response);
                        Vthis.showMessage(message, 'error');
                    })
                    .finally(() => {});

            },
            getStudents() {
            var Vthis = this;
            this.loading = true;
            this.selectedAddAppNum = this.form.class_id;
            this.axios
                .post('api/get-class-students', { class_id: this.form.class_id,exam_id: this.form.exam_id})
                .then((response) => {
                    //console.log(this.form.std_ids);
                   // this.selectedRecords = response.data;
                    this.records = response.data.records_all;
                    if (Array.isArray(response.data.selectedPapers)) {
                    this.selectedPapers = {}; // Initialize as an empty object
                    } else {
                    this.selectedPapers = response.data.selectedPapers;
                    }
                   
                    this.selectedRecords = response.data.records_all.filter((student) => {
                        // Assuming student has a property like 'student_id' to match against this.form.std_ids
                        return this.form.std_ids.includes(student.student_id);
                    });
                    this.loading = false;
                })
                .catch((error) => {
                    let message = Vthis.customError(error.response);
                    Vthis.showMessage(message, 'error');
                })
                .finally(() => {});
            },
            getAllClasses()
            {
                this.axios
                .post('api/get-class')
                .then((response) => {
                    this.selectedClass = response.data;
                    // this.loading = false;
                })
                .catch((error) => {
                    let message = Vthis.customError(error.response);
                    Vthis.showMessage(message, 'error');
                })
                .finally(() => {});
            },
            
        
            getAllRequestData(){   
                this.$axios.all([])   
                .then(() => {  
                    // this.viewRecord();   
                }) 
                .then(() => { 
                    this.blockComponent = false;     
                }).catch(() => {    
                //    this.getAllRequestData();
                })
                .finally(() => {  
                }); 
            },
           
            resetForm(){
                this.form.reset();
            },
            async save(){ 
                var Vthis = this; 
                this.isSaving = true;
                this.form.id = this.recId;
                this.form.selectedPapers = this.selectedPapers;
                this.form.std_ids = this.selectedRecords.map((record) => record.id);
                this.autoFormateDates();
                var url = Vthis.$baseurl+'api/save-initiation';
                if(this.recId > 0){ url = Vthis.$baseurl+'api/update-initiation'; }
                await this.form.post(url)
                .then(() => { 
                    if(this.saveAndClose){   
                        this.saveAndClose = false;
                        this.closeForm();
                    }   
                    Vthis.showMessage('Saved successfully','success',); 
                })
                .catch((error) => {   
                    let message = Vthis.customError(Vthis.form.errors.has('error') ? Vthis.form.errors.get('error') : error  ); 
                    Vthis.showMessage(message,'error'); 
                })
                .finally(() => {
                    this.isSaving = false; 
                    this.getAllRequestData();
                }) 
            }, 
            loadForm(){
                this.resetForm(); 
            },
            closeForm(){
                this.$emit('formClose',true); 
            },
            getTrueFalse(status) {
            switch (status) {
                case 1:
                    return 'success';

                case 0:
                    return 'danger';

                default:
                    return null;
                }
            },
            autoFormateDates()
            {
                if (this.form.from_date) {
                const fromDate = new Date(this.form.from_date);
                fromDate.setDate(fromDate.getDate() + 1);
                this.form.from_date = fromDate.toISOString();
                }

                // Add one day to this.form.to_date
                if (this.form.to_date) {
                const toDate = new Date(this.form.to_date);
                toDate.setDate(toDate.getDate() + 1);
                this.form.to_date = toDate.toISOString();
                }
            },
            getSeverity(data) {
                switch (data) {
                    case 1:
                        return 'success';
                    case 0:
                        return 'danger';
                    default:
                        return null;
                }
            },
            handlePapersChange(studentId) {
                // Check if the MultiSelect component is available
                if (this.$refs['multiSelect-' + studentId] && this.$refs['multiSelect-' + studentId].selectedItems) {
                if (!this.selectedPapers[studentId]) {
                    this.selectedPapers[studentId] = [];
                }
                // Update selectedPapers for the specific student
                this.selectedPapers[studentId] = [...this.$refs['multiSelect-' + studentId].selectedItems];
                }
            },
            getSeverityText(data) {
                switch (data) {
                    case 1:
                        return 'OPEN';
                    case 0:
                        return 'CLOSED';
                    default:
                        return null;
                }
            },
            getColumnStyle(field) {
                // Define styles based on column field
                if (field == 'action') {
                    return 'min-width:5rem; flex-grow:1;';  // Adjust the width as needed
                } else if (field == 'name' || field == 'father_name') {
                    return 'min-width:5rem; flex-grow:2;';  // Adjust the width as needed
                }else if (field == 'roll_no' || field == 'student_id') {
                    return 'min-width:2rem; flex-grow:1;';  // Adjust the width as needed
                }else if ( field == 'phone' || field == 'cnic') {
                    return 'min-width:2rem; flex-grow:2;';  // Adjust the width as needed
                }else if (field == 'student_papers') {
                    return 'min-width:2rem; flex-grow:10;';  // Adjust the width as needed
                } else {
                    return 'width:auto; min-width:10rem;flex-grow:1; flex-basis:160px;';  // Default width for other columns
                }
            },
           
            onToggle(value) {
                this.selectedColumns = this.columns.filter(col => value.includes(col));
             
            },
            initFilters() {
				this.filters = {
					'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
					'name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'nums_reg_no': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'roll_no': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                   
				};
			},
		},
        watch : {
             
        }
}
</script>
 
