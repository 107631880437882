<template>  
    <BlockUI :blocked="blockComponent">
        <Toast />
        <ConfirmPopup></ConfirmPopup>
        <Sidebar v-model:visible="formVisible" :baseZIndex="1000"  :showCloseIcon="false" :autoZIndex="true" :dismissable="false" @hide="closeForm" position="full" :class="'col-12 lg:col-8 md:col-10 sm:col-12 xs:col-12 mt-0'"> 
            <form  @keydown="form.onKeydown($event)" enctype="multipart/form-data"> 
                <div class="mt-0 mb-0">
                    <div class="col-12 lg:col-12 mb-0">
                        <h4 class="mb-0"><span>{{ form.class_name }} - {{ form.exam_name }} </span> <Tag :value="getSeverityText(form.is_active)" :severity="getSeverity(form.is_active)"></Tag>
                        </h4>
                     
                        <span><strong>Opened On: </strong> {{ formatDate(form.from_date) }} - <strong>Closed On: </strong> {{ formatDate(form.to_date) }} </span>
                        <span></span> 
                        <Button
                        @click="editRecord(form)"
                        v-tooltip.top="'Edit'"
                        type="button"
                        icon="pi pi-pencil"
                        :label="showBtnLabels ? 'Edit EAF' : ''"
                        :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'], [toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]"
                        class="p-button-raised p-button-info mr-2 mb-2"
                        style="float: right;margin-top: -25px;"
                        />
                    </div>
                    <Divider class="mt-0 mb-0" />
                   
                   
                    <Divider class="mt-0" />
                    <div >
                        <TabView class="tabview-custom">
                            <!-- <TabPanel>
                                <template #header>
                                    <span class="p-1">All Students</span>
                                    <Tag style="width: 35px;" :value="count_all" rounded></Tag>
                                </template>
                              
                                <Toolbar class="pl-0 pr-0">
                                    <template v-slot:start>
                                       
                                        <Button  v-tooltip.top="'Export'" icon="pi pi-external-link" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-warning mr-2" :label="showBtnLabels ? 'Export' : ''" @click="exportCSVTAll($event)" />
                                     
                                    </template> 
                                </Toolbar> 
                                    <DataTable :resizableColumns="true" ref="dt_all" :value="records" :paginator="true"  class="p-datatable-gridlines p-datatable-sm"  :rows="10" dataKey="student_id" :rowHover="true" 
                                           :loading="loading" :filters="filters_all" responsiveLayout="scroll"
                                        :rowsPerPageOptions="[10,20,50,100]" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" 
                                            :scrollable="true">
                                        
                                            <template #header>
                                                <div class="flex justify-content-between flex-column sm:flex-row">  
                                                    <span class="p-input-icon-left mb-2">
                                                        <i class="pi pi-search" />
                                                        <InputText v-model="filters_all['global'].value" placeholder="Keyword Search" style="width: 100%"/>
                                                    </span>
                                                    <MultiSelect :modelValue="selectedColumnsAll" :options="columns" optionLabel="header" @update:modelValue="onToggleAll"
                                                    placeholder="Select Columns" style="width: 20em"/>
                                                </div>
                                            </template>
                                            
                                            <template #empty> 
                                                <div class="table-message"> 
                                                    <img class="table-message-img" src="layout/images/no-record-found.png" alt="No record found" >
                                                </div>
                                            </template>
                                            <template #loading>
                                                Loading data. Please wait.
                                            </template> 
                                            
                                           
                                            <Column v-for="(col, index) of selectedColumnsAll" :field="col.field" :header="col.header" 
                                                :exportable="col.field == 'action' ? false:true" :sortable="col.field == 'action' ? false:true" 
                                                :key="col.field + '_' + index" :class="col.field == 'action' ? 'btnAction':''" 
                                                :style="getColumnStyle(col.field)"> 
                                            
                                            
                                                <template  #body="{data}">
                                                    <span v-if="col.field == 'nums_reg_no'">{{ data.nums_reg_no }}</span>
                                                    <span v-if="col.field == 'roll_no'">{{ data.roll_no }}</span>
                                                    <span v-if="col.field == 'name'" >{{ data.name}}</span>
                                                    <span v-if="col.field == 'father_name'" >{{ data.father_name}}</span>
                                                    <span v-if="col.field == 'phone'" >{{ data.phone}}</span>
                                                    <span v-if="col.field == 'cnic'" >{{ data.cnic}}</span>
                                                    <span v-if="col.field == 'student_id'" >{{ data.student_id}}</span>
                                                      
                                                </template>
                                            
                                                <template #filter="{filterModel}">
                                                    <span v-if="col.field == 'name'"> 
                                                        <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by name"/>
                                                    </span>   
                                                
                                                    <span v-else-if="col.field == 'status'">
                                                        <Dropdown v-model="filterModel.value" :options="statuses" placeholder="Any" class="p-column-filter" :showClear="true">
                                                            <template #value="slotProps">
                                                                <span :class="'customer-badge status-' + slotProps.value" v-if="slotProps.value">{{slotProps.value}}</span>
                                                                <span v-else>{{slotProps.placeholder}}</span>
                                                            </template>
                                                            <template #option="slotProps">
                                                                <span :class="'customer-badge status-' + slotProps.option">{{slotProps.option}}</span>
                                                            </template>
                                                        </Dropdown>
                                                    </span>  
                                                </template>    
                                            </Column>   
                                        </DataTable>
                            </TabPanel> -->
                            
                            <TabPanel>
                                <template #header>
                                    <span class="p-1">Submitted EAFs</span>
                                    <Tag style="width: 35px;" severity="success" :value="count_submitted" rounded></Tag>
                                </template>
                    
                                <Toolbar class="pl-0 pr-0">
                                    <template v-slot:start>
                                       <Button  v-tooltip.top="'Export'" icon="pi pi-external-link" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-warning mr-2" :label="showBtnLabels ? 'Export' : ''" @click="exportCSVTSubmitted($event)" />
                                        <Button  v-tooltip.top="'Download as separate PDFs'" icon="pi pi-cloud-download" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-primary mr-2" :label="showBtnLabels ? 'Download as Zip' : ''" @click="getBulkPDFs($event)" :disabled="isButtonDisabled" />
                                        <Button  v-tooltip.top="'Download as Single PDF'" icon="pi pi-download" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-success mr-2" :label="showBtnLabels ? 'Download as Single PDF' : ''" @click="getMergedPDF($event)" :disabled="isButtonDisabled" />
                                
                                    </template> 
                                </Toolbar> 
                                
                                <DataTable :resizableColumns="true"  ref="dt_submitted" :value="records_submitted" :paginator="true"  class="p-datatable-gridlines p-datatable-sm"  :rows="10" dataKey="student_id" :rowHover="true" 
                                    :loading="loading"  responsiveLayout="scroll" :filters="filters_submitted"
                                    :rowsPerPageOptions="[10,20,50,100]" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" v-model:selection="selectedRecords" 
                                    :scrollable="true" scrollHeight="400px">
                                   
                                    <template #header>
                                        <div class="flex justify-content-between flex-column sm:flex-row">  
                                            <span class="p-input-icon-left mb-2">
                                                <i class="pi pi-search" />
                                                <InputText v-model="filters_submitted['global'].value"  placeholder="Keyword Search" style="width: 100%"/>
                                            </span>
                                            <MultiSelect :modelValue="selectedColumnsSubmitted" :options="columns_submitted" optionLabel="header" @update:modelValue="onToggle"
                                                placeholder="Select Columns" style="width: 20em"/>
                                        </div>
                                    </template>
                                    
                                    <template #empty> 
                                        <div class="table-message"> 
                                            <img class="table-message-img" src="layout/images/no-record-found.png" alt="No record found" >
                                        </div>
                                    </template>
                                    <template #loading>
                                        Loading data. Please wait.
                                    </template> 
                                    
                                    <Column selectionMode="multiple" style="max-width:3rem;flex-grow:1; flex-basis:160px"></Column>
                                    <Column v-for="(col, index) of selectedColumnsSubmitted" :field="col.field" :header="col.header" 
                                        :exportable="col.field == 'action' ? false:true" :sortable="col.field == 'action' ? false:true" 
                                        :key="col.field + '_' + index" :class="col.field == 'action' ? 'btnAction':''" 
                                        :style="getColumnStyle(col.field)">  
                                      
                                       
                                        <template  #body="{data}">
                                           
                                           
                                            <span v-if="col.field == 'nums_reg_no'">{{ data.nums_reg_no }}</span>
                                            <span v-if="col.field == 'roll_no'">{{ data.roll_no }}</span>
                                           <img  v-if="col.field == 'name' && data.picture" :alt="data.name" :src="'https://fs.cmhlmc.com/storage/students/files/students/'+data.student_id+'/'+data.picture" class="tableProfileImg mr-2" />
                                            <img  v-if="col.field == 'name' && !data.picture" :alt="data.name" :src="filesUrl+'defaults/default.png'" class="tableProfileImg mr-2" />
                                            <span v-if="col.field == 'name'" @click="selectedChangeSubjectRecordId = data.id;
                                            submittedApplicationData = JSON.stringify(data);
                                            ">{{ data.name }}</span>
                                         
                                            <span v-if="col.field == 'created_at'">{{ formatDate(data.created_at) }}</span>
                                            <span v-if="col.field == 'mark_of_downloads'">{{ data.mark_of_downloads }}</span>
                                            <span v-if="col.field == 'father_name'" >{{ data.father_name}}</span>
                                            <span v-if="col.field == 'phone'" >{{ data.phone}}</span>
                                            <span v-if="col.field == 'cnic'" >{{ data.cnic}}</span>
                                            <span v-if="col.field == 'student_id'" >{{ zeroPad(data.student_id, 5)}}</span>
                                            <span v-if="col.field == 'student_papers'">{{ data.papersData }}</span>
                                            
                                            <span v-if="col.field == 'action'" @click="selectedChangeSubjectRecordId = data.id;submittedApplicationData = JSON.stringify(data);">
                                                <div :class="{'ml-auto': !isRTL, 'mr-auto': isRTL}">
                                                    <Button icon="pi pi-ellipsis-h" class="p-button-rounded p-button-text" :submittedApplicationData="JSON.stringify(data)" :recordId="data.id" @click="toggleRecordMenu"></Button>
                                                    <Menu ref="menu" :popup="true" :model="actions" ></Menu>
                                                </div> 
                                            </span> 
                                            
                                        </template>
                                       
                                         <template #filter="{filterModel}">
                                            <span v-if="col.field == 'name'"> 
                                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by name"/>
                                            </span>   
                                           
                                            <span v-else-if="col.field == 'status'">
                                                <Dropdown v-model="filterModel.value" :options="statuses" placeholder="Any" class="p-column-filter" :showClear="true">
                                                    <template #value="slotProps">
                                                        <span :class="'customer-badge status-' + slotProps.value" v-if="slotProps.value">{{slotProps.value}}</span>
                                                        <span v-else>{{slotProps.placeholder}}</span>
                                                    </template>
                                                    <template #option="slotProps">
                                                        <span :class="'customer-badge status-' + slotProps.option">{{slotProps.option}}</span>
                                                    </template>
                                                </Dropdown>
                                            </span>  
                                        </template>    
                                    </Column>   
                                </DataTable>
                            </TabPanel>
                            <TabPanel>
                                <template #header>
                                    <span class="p-1">Pending EAFs</span>
                                    <!-- <i class="pi pi-user ml-2"></i> -->
                                    <Tag style="width: 35px;" severity="warning" :value="count_pending" rounded></Tag>
                                </template>
                                <Toolbar class="pl-0 pr-0">
                                    <template v-slot:start>
                                       
                                        <Button  v-tooltip.top="'Export'" icon="pi pi-external-link" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-warning mr-2" :label="showBtnLabels ? 'Export' : ''" @click="exportCSVTPending($event)" />
                                     
                                    </template> 
                                </Toolbar>
                                    <DataTable :resizableColumns="true"  ref="dt_pending" :value="records_pending" :paginator="true" class="p-datatable-gridlines p-datatable-sm"  :rows="10" dataKey="id" :rowHover="true" 
                                    :loading="loading"  responsiveLayout="scroll" :filters="filters_pending" 
                                    :rowsPerPageOptions="[10,20,50,100]" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                    :scrollable="true" scrollHeight="400px">
                                
                                    
                                    <template #header>
                                        <div class="flex justify-content-between flex-column sm:flex-row">  
                                            <span class="p-input-icon-left mb-2">
                                                <i class="pi pi-search" />
                                                <InputText  v-model="filters_pending['global'].value" placeholder="Keyword Search" style="width: 100%"/>
                                            </span>
                                            <MultiSelect :modelValue="selectedColumnsPending" :options="columns_pending" optionLabel="header" @update:modelValue="onTogglePending"
                                                placeholder="Select Columns" style="width: 20em"/>
                                        </div>
                                    </template>
                                    
                                    <template #empty> 
                                        <div class="table-message"> 
                                            <img class="table-message-img" src="layout/images/no-record-found.png" alt="No record found" >
                                        </div>
                                    </template>
                                    <template #loading>
                                        Loading data. Please wait.
                                    </template> 
                                    
                                
                                    <Column v-for="(col, index) of selectedColumnsPending" :field="col.field" :header="col.header" 
                                        :exportable="col.field == 'action' ? false:true" :sortable="col.field == 'action' ? false:true" 
                                        :key="col.field + '_' + index" :class="col.field == 'action' ? 'btnAction':''" 
                                        :style="getColumnStyle(col.field)"> 
                                      
                                        <template  #body="{data}">

                                            <span v-if="col.field == 'nums_reg_no'">{{ data.nums_reg_no }}</span>
                                            <span v-if="col.field == 'roll_no'">{{ data.roll_no }}</span>
                                            <span v-if="col.field == 'name'" >{{ data.name}}</span>
                                            <span v-if="col.field == 'father_name'" >{{ data.father_name}}</span>
                                            <span v-if="col.field == 'phone'" >{{ data.phone}}</span>
                                            <span v-if="col.field == 'cnic'" >{{ data.cnic}}</span>
                                            <span v-if="col.field == 'student_id'" >{{ data.student_id}}</span>
                                        </template>
                                       
                                         <template #filter="{filterModel}">
                                            <span v-if="col.field == 'name'"> 
                                                <InputText type="text" class="p-column-filter" placeholder="Search by name"/>
                                            </span>   
                                           
                                            <span v-else-if="col.field == 'status'">
                                                <Dropdown v-model="filterModel.value" :options="statuses" placeholder="Any" class="p-column-filter" :showClear="true">
                                                    <template #value="slotProps">
                                                        <span :class="'customer-badge status-' + slotProps.value" v-if="slotProps.value">{{slotProps.value}}</span>
                                                        <span v-else>{{slotProps.placeholder}}</span>
                                                    </template>
                                                    <template #option="slotProps">
                                                        <span :class="'customer-badge status-' + slotProps.option">{{slotProps.option}}</span>
                                                    </template>
                                                </Dropdown>
                                            </span>  
                                        </template>    
                                    </Column>   
                                </DataTable>
                            </TabPanel>
                            <TabPanel>
                                <template #header>
                                    <span class="p-1">Pending by Student Affairs</span>
                                    <!-- <i class="pi pi-cog ml-2"></i> -->
                                    <Tag style="width: 35px;" severity="danger" :value="count_not_approved" rounded></Tag>
                                </template>
                                <Toolbar class="pl-0 pr-0">
                                    <template v-slot:start>
                                       
                                        <Button  v-tooltip.top="'Export'" icon="pi pi-external-link" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-warning mr-2" :label="showBtnLabels ? 'Export' : ''" @click="exportCSVTNotApproved($event)" />
                                     
                                    </template> 
                                </Toolbar>
                                <!-- <Toolbar class="pl-0 pr-0">
                                    <template v-slot:start>
                                        <h3>Applications Not Approved by Student Affairs</h3>
                                    </template>
                                   
                                </Toolbar> -->                                
                                <DataTable :resizableColumns="true"  ref="dt_not_approved" :value="records_not_approved" :paginator="true" class="p-datatable-gridlines p-datatable-sm"  :rows="10" dataKey="id" :rowHover="true" 
                                     :loading="loading" :filters="filters_not_approved" responsiveLayout="scroll"
                                    :rowsPerPageOptions="[10,20,50,100]" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                    :scrollable="true" scrollHeight="400px">
                                   
                                    <template #header>
                                        <div class="flex justify-content-between flex-column sm:flex-row">  
                                            <span class="p-input-icon-left mb-2">
                                                <i class="pi pi-search" />
                                                <InputText v-model="filters_not_approved['global'].value" placeholder="Keyword Search" style="width: 100%"/>
                                            </span>
                                            <MultiSelect :modelValue="selectedColumnsNotApproved" :options="columns_not_approved" optionLabel="header" @update:modelValue="onToggleNotApproved"
                                            placeholder="Select Columns" style="width: 20em"/>
                                          
                                        </div>
                                    </template>
                                    
                                    <template #empty> 
                                        <div class="table-message"> 
                                            <img class="table-message-img" src="layout/images/no-record-found.png" alt="No record found" >
                                        </div>
                                    </template>
                                    <template #loading>
                                        Loading data. Please wait.
                                    </template> 
                                    
                                   
                                    <Column v-for="(col, index) of selectedColumnsNotApproved" :field="col.field" :header="col.header" 
                                        :exportable="col.field == 'action' ? false:true" :sortable="col.field == 'action' ? false:true" 
                                        :key="col.field + '_' + index" :class="col.field == 'action' ? 'btnAction':''" 
                                        :style="getColumnStyle(col.field)"> 
                                      
                                       
                                        <template  #body="{data}">
                                           
                                            <!-- <span v-if="col.field == 'download'"><Button @click="getPDF(data.id,data.student_id,data.name,formatDate(data.created_at))" icon="pi pi-cloud-download" /></span> -->
                                            <!-- <span v-if="col.field == 'id'">{{ data.id }}</span> -->
                                            <span v-if="col.field == 'roll_no'">{{ data.roll_no }}</span>
        
                                           <img  v-if="col.field == 'name' && data.picture" :alt="data.name" :src="'https://fs.cmhlmc.com/storage/students/files/students/'+data.student_id+'/'+data.picture" class="tableProfileImg mr-2" />
                                            <img  v-if="col.field == 'name' && !data.picture" :alt="data.name" :src="filesUrl+'defaults/default.png'" class="tableProfileImg mr-2" />
                                            <span v-if="col.field == 'name'" @click="selectedChangeSubjectRecordId = data.id;
                                            selectedDataNotApproved = JSON.stringify(data);
                                            ">{{ data.name }}</span>
                                            <!-- <span v-if="col.field == 'father_name'">{{ data.father_name }}</span> -->
                                            <span v-if="col.field == 'verified_by_smp'"><Tag :value="data.verified_by_smp == 0 ? 'Pending' : 'Rejected'" :severity="getSeverityTable(data.verified_by_smp) " /></span>
                                            <span v-if="col.field == 'nums_reg_no'">{{ data.nums_reg_no }}</span>
                                            <span v-if="col.field == 'father_name'" >{{ data.father_name}}</span>
                                            <span v-if="col.field == 'phone'" >{{ data.phone}}</span>
                                            <span v-if="col.field == 'cnic'" >{{ data.cnic}}</span>
                                            <span v-if="col.field == 'student_id'" >{{ data.student_id}}</span>
                                            <!-- <span v-if="col.field == 'nationality'">{{ data.nationality }}</span> -->
                                         
                                            <!-- <span v-if="col.field == 'action'" @click="selectedChangeSubjectRecordId = data.id;selectedDataNotApproved = JSON.stringify(data);">
                                                <div :class="{'ml-auto': !isRTL, 'mr-auto': isRTL}">
                                                    <Button icon="pi pi-ellipsis-h" class="p-button-rounded p-button-text" :selectedData="JSON.stringify(data)" :recordId="data.id" @click="toggleRecordMenu"></Button>
                                                    <Menu ref="menu" :popup="true" :model="actions" ></Menu>
                                                </div> 
                                            </span>  -->
                                            
                                        </template>
                                       
                                         <template #filter="{filterModel}">
                                            <span v-if="col.field == 'name'"> 
                                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by name"/>
                                            </span>   
                                           
                                            <span v-else-if="col.field == 'status'">
                                                <Dropdown v-model="filterModel.value" :options="statuses" placeholder="Any" class="p-column-filter" :showClear="true">
                                                    <template #value="slotProps">
                                                        <span :class="'customer-badge status-' + slotProps.value" v-if="slotProps.value">{{slotProps.value}}</span>
                                                        <span v-else>{{slotProps.placeholder}}</span>
                                                    </template>
                                                    <template #option="slotProps">
                                                        <span :class="'customer-badge status-' + slotProps.option">{{slotProps.option}}</span>
                                                    </template>
                                                </Dropdown>
                                            </span>  
                                        </template>    
                                    </Column>   

                                </DataTable>
                            </TabPanel>
                        </TabView>
                    </div>
                    <Divider />
                    <div class="col-12 lg:col-12">
                        <Toolbar class="pl-0 pr-0">
                            <template v-slot:start>
                                
                                <!-- <Button type="submit" :label="(recordId && recordId > 0) ? 'Update' : 'Initiate'" :loading="isSaving" icon="pi pi-check" @click="save()" class="p-button-raised p-button-lg p-button-success mr-2 mb-2" v-if="!(recordId && recordId > 0)"/> -->
                                <!-- <Button type="submit" :label="(recordId && recordId > 0) ? 'Update' : 'Initiate'"  :loading="isSaving" icon="pi pi-check" @click="saveAndClose=true, save()" class="p-button-raised p-button-lg p-button-info mr-2 mb-2"/> -->
                                <!-- <Button type="button" label="Reset" icon="pi pi-refresh" class="p-right p-button-raised p-button-lg p-button-warning mr-2" @click="resetForm()"/> -->
                            </template>
                            <template v-slot:end> 
                                <Button type="button" label="Cancel" icon="pi pi-times" class="p-right p-button-raised p-button-lg p-button-danger mr-2" @click="closeView"/>
                            </template> 
                        </Toolbar>   
                    </div>
                </div> 
            </form>
        </Sidebar>
        <StudentApplicationsForm v-on:formClose="closeChangeSubjectForm" v-if="showChangeSubjectForm" :selectedData="submittedApplicationData" :recordId="selectedChangeSubjectRecordId" :showForm="true" />
        <InitializationForm  v-on:formClose="closeForm" v-if="showForm" :selectedData="selectedData2" :recordId="selectedRecordId" :showForm="true" />
        <ConfirmDialog group="dialog" />
    </BlockUI>
    
</template>
<style scoped>
    .active-room{
        background-color: aquamarine !important;
    }
</style>
<script>
import { FilterMatchMode, FilterOperator } from 'primevue/api'; 
import StudentApplicationsForm from './StudentApplicationsForm.vue';
import InitializationForm from './InitializationForm.vue';
export default {
    data() {
			return {  
                form: new this.Form({ 
                    id: '', 
                    name: '', 
                    class_id:'',
                    class_name:'',
                    exam_id:'',
                    exam_name:'',
                    exam_type:'',
                    from_date:null,
                    to_date:null,
                    is_active:true,
                    date_added:'',
                    std_ids:[],
                    total_students_applications:'',
                    total_students_per_class:'',
                }),   
                module_name:'Exam Admission Form', 
                actions: [], 
                isSaving:false,  
                records: null,
                records_submitted: null,
                records_pending: null,
                records_not_approved: null,
                loading: false,
                recId:0,
                count_all:0,
                count_submitted:0,
                count_pending:0,
                count_not_approved:0,
                columns: null,
                columns_pending: null,
                columns_not_approved: null,
                columns_submitted:null,
                selectedRecords: [],
                selectedColumnsAll:null,
                selectedColumnsSubmitted: null,
                selectedColumnsPending: null,
                selectedColumnsNotApproved: null,
                loadingComponent:true,
                formVisible:false, 
                selectedClass: [],
                selectedExam: [],
                selectedTableData:[],
                showForm: false,
                showChangeSubjectForm:false,
                selectedRecordId: 0,
                selectedChangeSubjectRecordId: 0,
                saveAndClose: false,
                submittedApplicationData: '',
                selectedData2: '',
                recordId2:0,
                selectedDataNotApproved: '',
                annual_or_supply:'',
                program:'',
                prof_year:'',
                institute:'',
                filters_all:{},
                filters_submitted:{},
                filters_pending:{},
                filters_not_approved:{},
                selectedCities: null,
                isButtonDisabled: true,
                cities: [
                    { name: 'New York', code: 'NY' },
                    { name: 'Rome', code: 'RM' },
                    { name: 'London', code: 'LDN' },
                    { name: 'Istanbul', code: 'IST' },
                    { name: 'Paris', code: 'PRS' }
                ]

                
			}
		},   
        props:{
            showView : {
                type : Boolean,
                required : true,
            },
            recordId :{
                type : Number,
            },
            selectedData:{
                type : String,
            }
        },
        components: {
            StudentApplicationsForm,
            InitializationForm,
         },
		created() {  
            this.actions.push({
            label: 'View',
            icon: 'pi pi-eye',
            command: () => {
                this.getClickedRecord('view');
            },
            });

            // this.actions.push({
            //     label: 'Change Papers',
            //     icon: 'pi pi-refresh',
            //     command: () => {
            //         this.getClickedRecord('update');
            //     },
            // });

            this.actions.push({
                label: 'Download',
                icon: 'pi pi-download',
                command: () => {
                    this.getClickedRecord('download');
                },
            });
            this.initFilters();
      
            this.columns = [
                {field: 'nums_reg_no', header: 'Registration Number'},    
                {field: 'roll_no', header: 'Roll No'},
                {field: 'name', header: 'Name'},
                {field: 'father_name', header: 'Father Name'}, 
                {field: 'phone', header: 'Phone No'}, 
                {field: 'cnic', header: 'CNIC'}, 
                {field: 'student_id', header: 'Student ID'},    
                ];
            this.selectedColumnsAll = [
                {field: 'nums_reg_no', header: 'Registration Number'},    
                {field: 'roll_no', header: 'Roll No'},
                {field: 'name', header: 'Name'},
                
                ];
            this.columns_submitted = [
                {field: 'action', header: 'Action'},
                // {field: 'id', header: 'Application Id'},
                {field: 'nums_reg_no', header: 'Registration Number'},    
                {field: 'roll_no', header: 'Roll No'}, 
                {field: 'name', header: 'Name'},
                {field: 'father_name', header: 'Father Name'}, 
                {field: 'phone', header: 'Phone No'}, 
                {field: 'cnic', header: 'CNIC'}, 
                {field: 'student_id', header: 'Student ID'},    
             
                {field: 'created_at', header: 'Submission Date'}, 
                {field: 'student_papers', header: 'Papers'},  
                {field: 'mark_of_downloads', header: 'Download'},  
               
            ];
            this.selectedColumnsSubmitted = [
                {field: 'action', header: 'Action'},
                // {field: 'id', header: 'Application Id'},
                {field: 'nums_reg_no', header: 'Registration Number'},    
                {field: 'roll_no', header: 'Roll No'}, 
                {field: 'name', header: 'Name'},

                {field: 'created_at', header: 'Submission Date'}, 
                {field: 'student_papers', header: 'Papers'},  
                {field: 'mark_of_downloads', header: 'Download'},  
               
            ];
            this.columns_pending = [
                {field: 'nums_reg_no', header: 'Registration Number'},    
                {field: 'roll_no', header: 'Roll No'},
                {field: 'name', header: 'Name'}, 
                {field: 'father_name', header: 'Father Name'}, 
                {field: 'phone', header: 'Phone No'}, 
                {field: 'cnic', header: 'CNIC'}, 
                {field: 'student_id', header: 'Student ID'},   
              //  {field: 'father_name', header: 'Father Name'}, 
               
            ];
            this.selectedColumnsPending = [
                {field: 'nums_reg_no', header: 'Registration Number'},    
                {field: 'roll_no', header: 'Roll No'},
                {field: 'name', header: 'Name'}, 
              
               
            ];
            this.columns_not_approved = [
                {field: 'nums_reg_no', header: 'Registration Number'},  
                {field: 'roll_no', header: 'Roll No'}, 
                {field: 'name', header: 'Name'}, 
                {field: 'father_name', header: 'Father Name'}, 
                {field: 'phone', header: 'Phone No'}, 
                {field: 'cnic', header: 'CNIC'}, 
                {field: 'student_id', header: 'Student ID'},   
               // {field: 'father_name', header: 'Father Name'}, 
                {field: 'verified_by_smp', header: 'Status'}, 
                 
               // {field: 'nationality', header: 'Nationality'},    
                
            ];
            this.selectedColumnsNotApproved = [
                {field: 'nums_reg_no', header: 'Registration Number'},  
                {field: 'roll_no', header: 'Roll No'}, 
                {field: 'name', header: 'Name'},  
                {field: 'verified_by_smp', header: 'Status'}, 
               
                
            ];
           
		},
        computed: { 
            isFormActive: {
                get() {
                    return this.form.is_active === 1;
                },
                set(value) {
                    this.form.is_active = value ? 1 : 0;
                },
            },
          
            
        },
		mounted() {  
            this.resetForm();
            this.getAllRequestData();  
            this.formVisible = this.showView; 
            this.recId = this.recordId; 
            this.selectedData ? this.form.fill(JSON.parse(this.selectedData)) : null;
            
            if (this.selectedData) {
                this.form.fill(JSON.parse(this.selectedData));
                if (this.form.from_date) {
                    const date = new Date(this.form.from_date);
                    this.form.from_date = date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: '2-digit' });
                }
                if (this.form.to_date) {
                    const date = new Date(this.form.to_date);
                    this.form.to_date = date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: '2-digit' });
                }
            }
            this.getData();
            this.getAllExams();
           
          
		},
		methods: {
            

            initFilters() {
				this.filters_all = {
					'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
					'name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'nums_reg_no': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'roll_no': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                   
				};
                this.filters_submitted = {
					'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
					'name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'nums_reg_no': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'roll_no': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                    'created_at': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]},
                    
				};
                this.filters_pending = {
					'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
					'name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'nums_reg_no': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'roll_no': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                  
				};
                this.filters_not_approved = {
					'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
					'name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'nums_reg_no': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'roll_no': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                    'verified_by_smp': {operator: FilterOperator.OR, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]} 
				};
              
			},

            getClickedRecord(action) {
            if (action == 'view') {
                this.getPDFView();
            } 
            else if (action == 'update') {
                this.showChangeSubjectForm = true;
               
            }
            else if (action == 'download') {
                this.getPDF();
            }
            },
            getData() {
            var Vthis = this;
            this.loading = true;
            this.axios
                .post('api/view-eaf', { add_app_id: this.form.id,class_id: this.form.class_id })
                .then((response) => {
                    this.records_submitted = response.data.records_submitted;
                    this.count_submitted = response.data.records_submitted.length;
                    this.records = response.data.records_all;
                    this.count_all = response.data.records_all.length;
                    this.records_not_approved = response.data.records_not_approved;
                    this.count_not_approved = response.data.records_not_approved.length;
                    this.records_pending = response.data.records_pending;
                    this.count_pending = response.data.records_pending.length;
                    
                    // this.selectedRecords = response.data.records_all.filter((student) => {
                    //     // Assuming student has a property like 'student_id' to match against this.form.std_ids
                    //     return this.form.std_ids.includes(student.student_id);
                    // });


                    // if (response.data.records_submitted[0]) {
                    //     this.annual_or_supply = response.data.records_submitted[0].annual_or_supply;
                    //     this.program = response.data.records_submitted[0].program;
                    //     this.prof_year = response.data.records_submitted[0].prof_year;
                    //     this.institute = response.data.records_submitted[0].institute;
                    // }
                    // else if (response.data.records_pending[0]) {
                    //     this.annual_or_supply = response.data.records_pending[0].annual_or_supply;
                    //     this.program = response.data.records_pending[0].program;
                    //     this.prof_year = response.data.records_pending[0].prof_year;
                    //     this.institute = response.data.records_pending[0].institute;
                    // }

                    this.loading = false;
                })
                .catch((error) => {
                    let message = Vthis.customError(error.response);
                    Vthis.showMessage(message, 'error');
                })
                .finally(() => {});
            
             },
            
            exportCSV() {
                this.$refs.dt.exportCSV();
            },
            exportCSVTAll(){
                this.$refs.dt_all.exportCSV();
            },
            exportCSVTPending() {
                this.$refs.dt_pending.exportCSV();
            },
            exportCSVTSubmitted() {
                this.$refs.dt_submitted.exportCSV();
            },
            exportCSVTNotApproved() {
                this.$refs.dt_not_approved.exportCSV();
            },
            
            
            onToggle(value) {
                console.log("Start");
                console.log(value);
                console.log(this.selectedColumnsSubmitted);
                console.log(this.columns_submitted);
                console.log("End");
                this.selectedColumnsSubmitted = []
                this.selectedColumnsSubmitted = this.columns_submitted.filter(col => value.includes(col));
             
            },
            onToggleAll(value) {
               
                this.selectedColumnsAll = this.columns.filter(col => value.includes(col));
            
            },
            onTogglePending(value) {
                this.selectedColumnsPending = this.columns_pending.filter(col => value.includes(col));
            },
            onToggleNotApproved(value) {
                this.selectedColumnsNotApproved = this.columns_not_approved.filter(col => value.includes(col));
            },
            getAllExams()
            {
                this.axios
                .post('api/get-exams')
                .then((response) => {
                    this.selectedExam = response.data;
                    // this.loading = false;
                })
                .catch((error) => {
                    let message = Vthis.customError(error.response);
                    Vthis.showMessage(message, 'error');
                })
                .finally(() => {});
            },
            
        
            getAllRequestData(){   
                this.$axios.all([])   
                .then(() => {  
                    // this.viewRecord();   
                }) 
                .then(() => { 
                    this.blockComponent = false;     
                }).catch(() => {    
                //    this.getAllRequestData();
                })
                .finally(() => {  
                }); 
            },
           
            resetForm(){
                this.form.reset();
            },
            getSeverity(data) {
                switch (data) {
                    case 1:
                        return 'success';
                    case 0:
                        return 'danger';
                    default:
                        return null;
                }
            },
            getSeverityTable(data) {
                switch (data) {
                    case 0:
                        return 'warning';
                    case 1:
                        return 'primary';
                    case 2:
                        return 'danger';
                    default:
                        return null;
                }
            },
            getSeverityText(data) {
                switch (data) {
                    case 1:
                        return 'OPEN';
                    case 0:
                        return 'CLOSED';
                    default:
                        return null;
                }
            },
            loadForm(){
                this.resetForm(); 
            },
            closeView(){
                this.$emit('formClose',true); 
            },
            // closeChangeSubjectForm(){
            //     this.showChangeSubjectForm = false;
            // },
            closeForm(){
                this.$emit('formClose',true); 
            },
            getTrueFalse(status) {
            switch (status) {
                case 1:
                    return 'success';

                case 0:
                    return 'danger';

                default:
                    return null;
                }
            },
            autoFormateDates()
            {
                if (this.form.from_date) {
                const fromDate = new Date(this.form.from_date);
                fromDate.setDate(fromDate.getDate() + 1);
                this.form.from_date = fromDate.toISOString();
                }

                // Add one day to this.form.to_date
                if (this.form.to_date) {
                const toDate = new Date(this.form.to_date);
                toDate.setDate(toDate.getDate() + 1);
                this.form.to_date = toDate.toISOString();
                }
            },
            editRecord(data) {
               
                this.selectedRecordId = data.id;
                console.log(this.selectedRecordId);
                this.selectedData2 = JSON.stringify(data);
                this.showForm = true;
            },
            loadChangeSubjectForm() {
            this.selectedChangeSubjectRecordId = 0;
            this.selectedPendingRecordId = 0;
            this.showChangeSubjectForm = true;
            },
            closeChangeSubjectForm() {
                // console.log(this.selectedSubject)
               // this.getRecords(this.selectedAddAppNum);
                this.showChangeSubjectForm = false;
               // this.showView = false;
            },
            toggleRecordMenu(event) {
            this.selectedChangeSubjectRecordId = event.currentTarget.getAttribute('recordId');
            //this.selectedPendingRecordId = event.currentTarget.getAttribute('pendingRecordId');
            this.submittedApplicationData = event.currentTarget.getAttribute('selectedData');
            this.$refs.menu.toggle(event);
            },
            // addRecord()
            // {
            //     this.selectedData2 = null;
            //     this.selectedRecordId = 0;
            //     this.showForm = true;
            // },
            // changeSubjectRecord()
            // {
            //     this.selectedData2 = null;
            //     this.selectedRecordId = 0;
            //     this.showChangeSubjectForm = true;
            // },
            getPDF() {
                this.loading = true;
                console.log(this.submittedApplicationData);
                const data = JSON.parse(this.submittedApplicationData);
                const options = { year: 'numeric', month: 'long', day: 'numeric' };
                 
                // Extract the desired values
                const id = data.id;
                const roll_no = data.roll_no;
                const name = data.name;
                const date = new Date(data.created_at).toLocaleDateString(undefined, options);

                this.axios.get('api/get-pdf', {
                    params: { 
                        status: this.getStatus,
                        id: id,
                    },
                    responseType: 'blob',
                    headers: {
                    'Content-Type': 'multipart/form-data',
                    },
                })
                .then((response) => {
                    console.log(response.data);
                    // Create a blob from the response data
                    const blob = new Blob([response.data], { type: 'application/pdf' });

                    // Create a URL for the blob
                    const url = window.URL.createObjectURL(blob);

                    // Create a link element to trigger the download
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = "NUMS_Form_"+name+"_"+roll_no+"_"+date+'.pdf'; // Set the desired file name

                    // Trigger the click event on the link to start the download
                    link.click();

                    // Clean up
                    window.URL.revokeObjectURL(url);
                    this.loading = false;
                })
                .catch((error) => {
                    let message = this.customError(error.response);
                    this.showMessage(message, 'error');
                    this.loading = false;
                });
            },
            getPDFView() {
                this.loading = true;
                console.log(this.submittedApplicationData);
                const data = JSON.parse(this.submittedApplicationData);
                const options = { year: 'numeric', month: 'long', day: 'numeric' };
                
                // Extract the desired values
                const id = data.id;
                const stu_id = data.student_id;
                
                const name = data.name;
                const date = new Date(data.created_at).toLocaleDateString(undefined, options);

                this.axios.get('api/get-pdf-view', {
                    params: { 
                        status: this.getStatus,
                        id: id,
                    
                    },
                    responseType: 'blob',
                    headers: {
                    'Content-Type': 'multipart/form-data',
                    },
                })
                .then((response) => {
                    console.log(response.data);
                    // Create a blob from the response data
                    const blob = new Blob([response.data], { type: 'application/pdf' });
                    const url = window.URL.createObjectURL(blob);
                    //console.log(response.data);
                    // Open the PDF in a new window/tab
                    window.open(url, '_blank');

                    this.loading = false;
                })
                .catch((error) => {
                    let message = this.customError(error.response);
                    this.showMessage(message, 'error');
                    this.loading = false;
                });
            },

            getBulkPDFs() {
            this.loading = true;
           const fileName = "NUMS Forms "+this.form.class_name +" - "+ this.form.exam_name ;
            // Extract the 'id' values from selectedRecords
            const ids = this.selectedRecords.map((record) => record.id);

            this.axios.post('api/get-pdfs', { ids: ids,fileName: fileName }, {
                responseType: 'blob',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then((response) => {
                // Create a blob from the response data
                const blob = new Blob([response.data], { type: 'application/zip' });

                // Create a URL for the blob
                const url = window.URL.createObjectURL(blob);

                // Create a link element to trigger the download
                const link = document.createElement('a');
                link.href = url;
                link.download = "NUMS Forms "+this.form.class_name +" - "+ this.form.exam_name +".zip"; 

                // Trigger the click event on the link to start the download
                link.click();

                // Clean up
                window.URL.revokeObjectURL(url);
                this.loading = false;
            })
            .catch((error) => {
                let message = this.customError(error.response);
                this.showMessage(message, 'error');
                this.loading = false;
            });
            },
            
            getMergedPDF() {
            this.loading = true;
            const fileName = "NUMS Forms "+this.form.class_name +" - "+ this.form.exam_name ;
            const ids = this.selectedRecords.map((record) => record.id);
           
            this.axios.post('api/get-merged-pdf', { ids: ids,fileName : fileName }, {
                responseType: 'blob',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then((response) => {
                // Create a blob from the response data
                const blob = new Blob([response.data], { type: 'application/zip' });

                // Create a URL for the blob
                const url = window.URL.createObjectURL(blob);

                // Create a link element to trigger the download
                const link = document.createElement('a');
                link.href = url;
                link.download = "NUMS Forms "+this.form.class_name +" - "+ this.form.exam_name +".zip"; 

                // Trigger the click event on the link to start the download
                link.click();

                // Clean up
                window.URL.revokeObjectURL(url);
                this.loading = false;
            })
            .catch((error) => {
                let message = this.customError(error.response);
                this.showMessage(message, 'error');
                this.loading = false;
            });
            },

            getProfYearLabel(profYear) {
                if (profYear == 1) {
                    return '1st Prof Year';
                } else if (profYear == 2) {
                    return '2nd Prof Year';
                } else if (profYear == 3) {
                    return '3rd Prof Year';
                } else if (profYear == 4) {
                    return `${profYear}th Prof Year`;
                } else if (profYear == 5) {
                    return `Final Prof Year`;
                } else {
                    return `${profYear}`; // Handle other cases if needed
                }
            },
            getColumnStyle(field) {
                // Define styles based on column field
                if (field == 'action') {
                    return 'max-width:5rem;flex-grow:1; flex-basis:160px';  // Adjust the width as needed
                } else if (field == 'name' || field == 'father_name') {
                    return 'min-width:5rem; flex-grow:2;';  // Adjust the width as needed
                }else if (field == 'roll_no' || field == 'student_id' || field == 'mark_of_downloads'|| field == 'created_at') {
                    return 'min-width:2rem; flex-grow:1;';  // Adjust the width as needed
                }else if ( field == 'phone' || field == 'cnic') {
                    return 'min-width:2rem; flex-grow:2;';  // Adjust the width as needed
                }else if (field == 'student_papers') {
                    return 'min-width:2rem; flex-grow:10;';  // Adjust the width as needed
                } else {
                    return 'width:auto; min-width:10rem;flex-grow:1; flex-basis:160px;';  // Default width for other columns
                }
            },
            
           
            
		},
        watch : {
            selectedRecords: {
            handler(newSelectedRecords) {
                this.isButtonDisabled = newSelectedRecords.length === 0;
                console.log(this.isButtonDisabled);
                console.log(JSON.stringify(this.selectedRecords));
            },
            immediate: true, // This ensures the watcher runs immediately when the component is created
            },
             
        }
}
</script>
 
